@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-famiy: Inconsolata;
  font-weight: 100 900;
  src: url(fonts/Inconsolata-VariableFont_wdth,wght.ttf);
}

@font-face {
  font-family: Catamaran;
  font-weight: 100 900;
  src: url(fonts/Catamaran-VariableFont_wght.ttf);
}

@font-face {
  font-family: Labrada;
  font-weight: 100 900;
  src: url(fonts/Labrada-VariableFont_wght.ttf);
}

@font-face {
  font-family: NotoSansDisplay;
  font-weight: 100 900;
  src: url(fonts/NotoSansDisplay-VariableFont_wdth,wght.ttf);
}


body {
  font-family: Helvetica, sans-serif;
  color: white;
  width: 100%;
  overflow-x: hidden;
}

.ens-pfp {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

ul li {
  padding: 5px;
  margin-left: 10px;
  list-style-type: disc;
}

button {
  white-space: nowrap
}

input[type="color"] {}