.myslider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background:#555;
    cursor: pointer;
  }
  
.myslider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #555;
    cursor: pointer;
}

.myslider2::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
}

.myslider2::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
}